import { CheckCircleIcon, ArrowPathIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { useEffect, useState } from 'react';
import { Switch } from '@headlessui/react'
import { classNames } from '@/lib/utils';
import { KeepaliveTestResponse, KeepaliveConfiguration, keepUrlAlive, getCurrentConfig, disableKeepalive } from '@/lib/api';
import { useRouter } from 'next/router';

export default function KeepaliveConfiguration() {
    const [subdomain, setSubdomain] = useState<string>('');
    const [path, setPath] = useState<string>('');
    const [enabled, setEnabled] = useState(false);
    const [errorNumber, setErrorNumber] = useState(0);
    const [lastCheckTime, setLastCheckTime] = useState<Date>();

    const [status, setStatus] = useState<string>('idle');
    const [statusLabel, setStatusLabel] = useState<React.JSX.Element>(<p className="text-sm text-gray-600">Send a request to an endpoint on your server</p>);
    const router = useRouter();

    useEffect(() => {
        if (!router.isReady) {
            return;
        }

        getCurrentConfig()
            .then((response) => {
                return response.json();
            }).then((body: KeepaliveConfiguration) => {
                if (body.url) {
                    let url = new URL(body.url);

                    let urlParts = body.url.split(url.hostname);
                    let path = "";
                    if (urlParts.length > 1) {
                        // has a path
                        path = urlParts[1];
                        if (path.startsWith("/")) {
                            path = path.substring(1);
                        }
                    }

                    let subdomainWithoutProtocol = url.hostname.split(".")
                    setSubdomain(subdomainWithoutProtocol.slice(0, subdomainWithoutProtocol.length - 2).join("."));
                    setPath(path);
                    setErrorNumber(body.errorNumber);
                    setLastCheckTime(body.updateDate);
                }
                setEnabled(body.status === 'ENABLED');
            });
    }, [router]);

    const createFeedbackMessage = (response: KeepaliveTestResponse) => {
        if (response.message.includes('Timeout')) {
            return <><p className="text-sm text-gray-600">I timed out trying to call <b>{response.url}</b>.</p><p className="text-sm text-gray-600"><u>This is normal if your server is in the process of waking up.</u> Check the logs of your server to verify when it&apos;s awake, then try again.</p><p className="text-sm text-gray-600">If your server is awake but takes longer than 3 seconds to respond, create an endpoint that responds more quickly that I can call.</p></>;
        }

        return <p className="text-sm text-gray-600">I got a <a href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Status">response code</a> of <b>{response.httpStatus}</b> when calling <b>{response.url}</b>. {response.message}</p>
    };

    const checkKeepaliveUrl = async () => {
        setStatus('in progress');
        setStatusLabel(<p className="text-sm">Calling out...</p>);

        keepUrlAlive(`https://${subdomain}.onrender.com/${path}`)
            .then((response) => {
                if (response.status !== 200) {
                    console.log("something happened on the server");
                    setStatus("error");
                    setStatusLabel(<p className="text-sm">Something unexpected happened on my backend- can you try again?</p>);
                    throw new Error("something happened on the server");
                }
                return response.json();
            }).then((response: KeepaliveTestResponse) => {
                setEnabled(response.success);
                setStatus(response.success ? 'success' : 'error');
                setErrorNumber(0);
                setStatusLabel(response.success ? <p className="text-sm text-gray-600">Success! Got a valid response code of {response.httpStatus} calling <b>{response.url}</b></p> : createFeedbackMessage(response));
            });
    };

    const onEnabledChange = async (newState: boolean) => {
        if (newState == false) {
            disableKeepalive().then((response) => {
                if (response.status !== 200) {
                    throw new Error("Something happened during the request to disable the keepalive");
                }
                return response.json();
            }).then((response: KeepaliveConfiguration) => {
                setEnabled(response.status === 'ENABLED');
                setErrorNumber(response.errorNumber);
                setLastCheckTime(response.updateDate);
            });
        }
    };


    const requestButtonWithFeedback = (status: string) => {
        // idle
        let icon = <></>;
        let style = "bg-blue-50";

        if (status === 'in progress') {
            icon = <ArrowPathIcon className="animate-spin -mr-0.5 h-6 w-6 text-blue-400" aria-hidden="true" />;
            style = "bg-blue-50";
        } else if (status === 'error') {
            icon = <XMarkIcon className="-mr-0.5 h-6 w-6 text-red-400" aria-hidden="true" />;
            style = "bg-red-50";
        } else if (status === 'success') {
            icon = <CheckCircleIcon className="-mr-0.5 h-6 w-6 text-green-400" aria-hidden="true" />;
            style = "bg-green-50";
        }

        return (
            <div className='flex items-start p-2'>
                <button
                    disabled={status === 'in progress' || subdomain === ''}
                    type="button"
                    className={
                        classNames(status === 'in progress' || subdomain === '' ?
                            "bg-indigo-400"
                            : "bg-indigo-600 focus-visible:outline-indigo-600 hover:bg-indigo-500",
                            "inline-flex items-center grow-0 shrink-0 py-2.5 gap-x-2 rounded-md px-3.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2")}
                    onClick={() => {
                        checkKeepaliveUrl();
                    }}
                >
                    Keep this URL alive!
                </button>
                <div className="pl-2 pr-1 pt-2">
                    {icon}
                </div>
                <div className="pt-2.5">{statusLabel}</div>
            </div>
        );
    };


    return (
        <form>
            <div className="space-y-12 sm:space-y-16">
                <div>
                    <h2 className="text-base font-semibold leading-7 text-gray-900">I will keep your free tier app awake!</h2>
                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">Once an endpoint is set and a successful call is made to it, I&apos;ll call your URL every 5-10 minutes to keep your server up.</p>
                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">If your service stops returning a <a className="underline text-indigo-600 text-[14px]" href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Status#successful_responses">200</a> or <a className="underline text-indigo-600 text-[14px]" href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Status#redirection_messages">300</a> series HTTP response code after 15 calls, I stop calling you.</p>
                    <p className="pl-2 pt-2 text-[20px] text-gray-600">-Matt <a href="https://twitter.com/mdgale" className="underline text-indigo-600 text-[14px]">(say hi on twitter!)</a></p>

                    <div className="mt-10 space-y-8 border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                App URL
                            </label>
                            <div className="sm:col-span-2 sm:mt-0">
                                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
                                    <span className="flex select-none items-center pl-3 pr-1 text-gray-500 sm:text-sm">https://</span>
                                    <input
                                        type="text"
                                        name="subdomain"
                                        id="subdomain"
                                        autoComplete="subdomain"
                                        className="block w-4/12 flex text-right border-0 bg-transparent py-1.5 pl-1 pr-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                        placeholder="my-app-5kxn"
                                        value={subdomain}
                                        onChange={(e) => setSubdomain(e.target.value)}
                                    />
                                    <span className="flex select-none items-center pl-1 pr-1 text-gray-500 sm:text-sm">.onrender.com/</span>
                                    <input
                                        type="text"
                                        name="path"
                                        id="path"
                                        autoComplete="path"
                                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                        placeholder="api/healthcheck (optional)"
                                        value={path}
                                        onChange={(e) => setPath(e.target.value)}
                                    />
                                </div>
                                {requestButtonWithFeedback(status)}
                            </div>
                        </div>
                    </div>

                    <div className="mt-1 space-y-8 border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
                        <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                            <label htmlFor="username" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                Enabled
                            </label>
                            <div className="sm:col-span-2 sm:mt-0">
                                <Switch
                                    disabled={!enabled}
                                    checked={enabled}
                                    onChange={onEnabledChange}
                                    className={classNames(
                                        enabled ? 'bg-indigo-600' : 'bg-gray-200',
                                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                                    )}
                                >
                                    <span
                                        aria-hidden="true"
                                        className={classNames(
                                            enabled ? 'translate-x-5' : 'translate-x-0',
                                            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                        )}
                                    />
                                </Switch>
                                {errorNumber > 0 && lastCheckTime && <div className='flex items-start'><XMarkIcon className="-ml-1 -mr-0.5 h-6 w-6 text-red-400" aria-hidden="true" /><p className="pt-1 text-xs text-gray-500"> I&apos;ve called your service {errorNumber} times and gotten errors each time. The last time I called it was at {lastCheckTime.toLocaleString()} UTC</p></div>}
                                {!enabled && <p className="text-xs text-gray-500">Enable the keepalive by having a successful request to your url above.</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}