import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid'
import { classNames } from '@/lib/utils'
import ApplicationShell from '@/components/applicationShell'
import KeepaliveConfiguration from '@/components/keepaliveConfiguration'



export default function Home() {
  return (
    <ApplicationShell>
      <KeepaliveConfiguration />
    </ApplicationShell>
  )
}
